@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  overflow-x: hidden;
  font-family: Montserrat;
  scroll-behavior: smooth;
}
.montalt{
  font-family: 'Montserrat Alternates';
}
.monts{
  font-family: Montserrat;
}
.material-symbols-rounded{
  overflow: hidden;
}