main{
  background-image: url(./bg.jpg);
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 80vh;
}
.cards{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 500px));
  grid-gap: 150px 250px;
}
@media (max-width: 770px) {
  .inverted{
    color: white;
  }
}